import React, { useEffect, useRef, useState } from 'react';

const AboutExperiences = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [aboutExImage, setAboutExImage] = useState({});
  const [aboutEx, setAboutEx] = useState([]);

  useEffect(() => {
    if (aboutEx) {
      contentRefs.current.forEach((content, index) => {
        if (content) {
          content.style.height =
            activeIndex === index ? `${content.scrollHeight + 20}px` : '0px';
        }
      });
    }
  }, [activeIndex, aboutEx]);
  

  useEffect(() => {
    const fetchAboutEXImage = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-ex-item`);
      const data = await res.json();
      setAboutExImage(data);
    };
    const fetchAboutAward = async () => {
      const res = await fetch(
        'https://api.chaulanart.com/about/about-ex-content'
      );
      const data = await res.json();
      setAboutEx(data);
    };
    fetchAboutAward();
    fetchAboutEXImage();
  }, []);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const checkMP4 = (url) => {
    return /\.(mp4)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  // Event handlers
  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="about-ex">
      <div className="about-ex-top">
        <h3
          className="about-ex-heading"
          dangerouslySetInnerHTML={{ __html: aboutExImage.heading }}
        />
      </div>
      <div className="about-ex-wrapper">
        <div className="about-ex-image">
          <div
            className="about-ex-item item-1"
            style={{ backgroundColor: aboutExImage.color_1 }}>
            {checkURL(aboutExImage.image_item_1) ? (
              <img
                src={aboutExImage.image_item_1}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_1) ? (
              <iframe
                src={aboutExImage.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_1) ? (
              <iframe
                src={aboutExImage.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_1 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-2"
            style={{ backgroundColor: aboutExImage.color_2 }}>
            {checkURL(aboutExImage.image_item_2) ? (
              <img
                src={aboutExImage.image_item_2}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_2) ? (
              <iframe
                src={aboutExImage.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_2) ? (
              <iframe
                src={aboutExImage.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_2 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-3"
            style={{ backgroundColor: aboutExImage.color_3 }}>
            {checkURL(aboutExImage.image_item_3) ? (
              <img
                src={aboutExImage.image_item_3}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_3) ? (
              <iframe
                src={aboutExImage.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_3) ? (
              <iframe
                src={aboutExImage.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_3 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-4"
            style={{ backgroundColor: aboutExImage.color_4 }}>
            {checkURL(aboutExImage.image_item_4) ? (
              <img
                src={aboutExImage.image_item_4}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_4) ? (
              <iframe
                src={aboutExImage.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_4) ? (
              <iframe
                src={aboutExImage.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_4 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-5"
            style={{ backgroundColor: aboutExImage.color_5 }}>
            {checkURL(aboutExImage.image_item_5) ? (
              <img
                src={aboutExImage.image_item_5}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_5) ? (
              <iframe
                src={aboutExImage.image_item_5}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_5) ? (
              <iframe
                src={aboutExImage.image_item_5}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_5 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-6"
            style={{ backgroundColor: aboutExImage.color_6 }}>
            {checkURL(aboutExImage.image_item_6) ? (
              <img
                src={aboutExImage.image_item_6}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_6) ? (
              <iframe
                src={aboutExImage.image_item_6}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_6) ? (
              <iframe
                src={aboutExImage.image_item_6}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_6 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-7"
            style={{ backgroundColor: aboutExImage.color_7 }}>
            {checkURL(aboutExImage.image_item_7) ? (
              <img
                src={aboutExImage.image_item_7}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_7) ? (
              <iframe
                src={aboutExImage.image_item_7}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_7) ? (
              <iframe
                src={aboutExImage.image_item_7}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_7 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-8"
            style={{ backgroundColor: aboutExImage.color_8 }}>
            {checkURL(aboutExImage.image_item_8) ? (
              <img
                src={aboutExImage.image_item_8}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_8) ? (
              <iframe
                src={aboutExImage.image_item_8}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_8) ? (
              <iframe
                src={aboutExImage.image_item_8}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_8 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-9"
            style={{ backgroundColor: aboutExImage.color_9 }}>
            {checkURL(aboutExImage.image_item_9) ? (
              <img
                src={aboutExImage.image_item_9}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_9) ? (
              <iframe
                src={aboutExImage.image_item_9}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_9) ? (
              <iframe
                src={aboutExImage.image_item_9}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_9 }}
              />
            )}
          </div>
          <div
            className="about-ex-item item-10"
            style={{ backgroundColor: aboutExImage.color_10 }}>
            {checkURL(aboutExImage.image_item_10) ? (
              <img
                src={aboutExImage.image_item_10}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_10) ? (
              <iframe
                src={aboutExImage.image_item_10}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : checkMP4(aboutExImage.image_item_10) ? (
              <iframe
                src={aboutExImage.image_item_10}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-ex-img"
              />
            ) : (
              <span
                className="about-ex-item-text"
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_10 }}
              />
            )}
          </div>
        </div>
        <div className="about-ex-content">
          {aboutEx &&
            aboutEx.map((item, index) => (
              <div
                key={index}
                className="about-ex-accordion">
                <div
                  className={`about-ex-accordion-header ${
                    activeIndex === index ? 'is-active' : ''
                  }`}
                  onClick={() => handleAccordionClick(index)}>
                  <span
                    className="about-ex-accordion-title"
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  />
                  <div className="about-ex-accordion-list">
                    <span
                      className={`about-ex-accordion-text ${
                        activeIndex === index ? 'is-active' : ''
                      }`}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <div
                      className={`about-ex-accordion-icon ${
                        activeIndex === index ? 'is-active' : ''
                      }`}></div>
                  </div>
                </div>
                <div
                  ref={(el) => (contentRefs.current[index] = el)}
                  className={`about-ex-accordion-content ${
                    activeIndex === index ? 'is-active' : ''
                  }`}
                  onClick={() => handleAccordionClick(index)}>
                  <p
                    className="about-ex-accordion-desc"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <p
                    className="about-ex-accordion-inner"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default AboutExperiences;
